<template>
  <div class="home" :style="'height:' + windowHeight + 'px'">
    <div class="scroll-box" :style="'top:' + scrollTop + 'px'">
      <!-- 菜单 -->
      <my-nav id="myNav"></my-nav>
      <!-- banner -->
      <div class="banner-box" :style="imgBoxStyle(windowHeight - 100)"></div>
      <!-- about Us -->
      <div class="about-box" id="aboutUs" :style="imgBoxStyle(windowHeight)">
        <div class="about-center">
          <div class="about-title">ABOUT US</div>
          <p>
            BenBenUnion was founded in 2005 in Guangzhou, China.After 16 years
            of development, it has been focusing on the research and development
            of engine and chassis parts for Mercedes-Benz, BMW, Volkswagen, Audi
            and Porsche.
          </p>
          <p>
            Benben Union is a professional automotive aftermarket supply chain
            management company that adheres to brand development and supervises
            OEM standards.
          </p>
          <p>
            With 16 years of intensive work in the industry and relying on this
            sound supply chain management system, Benben Union is committed to
            building the first cost-effective brand in the global automotive
            aftermarket. Adhering to the mission of "everything is centered on
            customer needs, and constantly meeting customer needs", we provide
            customers with one-stop, full-category, cost-effective solutions.
          </p>
        </div>
      </div>
      <!-- video -->
      <div class="video-box"  id="video" :style="'height:' + windowHeight + 'px;'">
        <div class="wrap">
          <div class="video-img">
            <img src="../../assets/images/video-img.jpg" :style="'height:' + (windowHeight * 0.63) + 'px;'"/>
          </div>
          
          <!-- <video src="/i/movie.ogg" controls="controls" :style="'height:' + (windowHeight * 0.63) + 'px;'"></video> -->
          <ul class="video-item clearfix">
            <li v-for="item in videoData" :key="item.title">
              <div class="video-count">{{ item.count }}</div>
              <div class="video-title">{{ item.title }}</div>
            </li>
          </ul>
        </div>
      </div>
      <!-- product -->
      <div class="product-box" id="product" :style="'height:' + windowHeight + 'px;'">
        <div class="wrap">
          <div class="product-top">
            <ul class="product-side" :style="'height:' + (windowHeight * 0.67) + 'px;'">
              <li @click="changeProductType(index)" v-for="(item,index) in productTypeList" :key="item.title" :class="index === productTypeIndex ? 'active' : ''">
                <div>{{ item.title }}</div>
              </li>
            </ul>
            <img :src="require('../../assets/images/'+ currentProductImg + '-big-0' + currentSmallNum + '.jpg')" class="product-big-img" :style="'height:' + (windowHeight * 0.67) + 'px;'">
          </div>
          <div class="product-item" :style="'height:' + (windowHeight * 0.24) + 'px;'">
            <img v-for="(item,index) in 4" @click="changeProductImg(item)" :key="index" :src="require('../../assets/images/' + currentProductImg + '-small-0' + item + '.jpg')">
          </div>
        </div>
      </div>
      <!-- standards -->
      <div class="standards-box" id="standards" :style="'height:' + windowHeight + 'px;'">
        <div class="wrap">
          <div class="standards-article">
            <div class="standards-article-title">OEM STANDAEDS</div>
            <div class="standards-article-big-text">
              <span>30,000+</span>
              The product categories 
            </div>
            <div class="standards-article-text">covering suspension systems, steering systems, braking systems, etc.</div>
            <div class="standards-article-text">At present, Benben Union's sales network has covered more than 40 countries and regions overseas, and has an in-depth understanding of the needs of car models in various countries. </div>
          </div>
          <div class="standards-introduce clearfix" :style="'margin-top:' + (windowHeight * 0.096) + 'px;'">
            <div class="standards-introduce-item">
              <img src="../../assets/images/icon01.png"  :style="{'height': (windowHeight * 0.14) + 'px','width': (windowHeight * 0.14) + 'px'}"  alt="">
              <div class="standards-introduce-text">Implement 40,000 km warranty for 2 years;</div>
            </div>
            <div class="standards-introduce-item">
              <img src="../../assets/images/icon02.png"  :style="{'height': (windowHeight * 0.14) + 'px','width': (windowHeight * 0.14) + 'px'}"  alt="">
              <div class="standards-introduce-text">One-to-one, point-to-point exclusive customer service docking, providing efficient after-sales service;</div>
            </div>
            <div class="standards-introduce-item">
              <img src="../../assets/images/icon03.png"  :style="{'height': (windowHeight * 0.14) + 'px','width': (windowHeight * 0.14) + 'px'}"  alt="">
              <div class="standards-introduce-text"> Powerful enterprise IT support, product query and model matching system, provide fast and accurate data services;</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 页脚 -->
      <my-footer id="footer"></my-footer>
    </div>
    <!-- 侧边导航 -->
    <ul class="side-nav">
      <li @click="scrollIntoView(index)" :class="{ active: index === currentSideNav }" v-for="(item, index) in sideNavList" :key="index">
        <i class="iconfont icon-shousuoxiajiantou"></i>
      </li>
    </ul>
  </div>
</template>

<script>
import MyNav from "../../components/nav/nav";
import MyFooter from "../../components/footer/footer";
export default {
  name: "Home",
  components: {
    MyNav,
    MyFooter,
  },
  data() {
    return {
      sideNavList: ["myNav", "aboutUs", "video", "product", "standards","footer"],
      //侧边导航当前位置索引
      currentSideNav: 0,
      scrollOption: {
        behavior: "smooth",
        block: "center",
      },
      windowHeight: document.documentElement.clientHeight,
      //页面内容y轴位置
      scrollTop: 0,
      videoData: [
        {
          count: '2005',
          title: 'The company was founded'
        },
        {
          count: '3000+',
          title: 'The product categories'
        },
        {
          count: '40',
          title: 'Covering countries and regions'
        }
      ],
      currentProductImg: 'engineSystem',
      currentSmallNum: 1,
      productTypeList: [
        {
          title: 'Engine System',
          imgName: 'engineSystem'
        },
        {
          title: 'Steering System',
          imgName: 'steeringSystem'
        },
        {
          title: 'Suspension System',
          imgName: 'suspensionSystem'
        },
        {
          title: 'Transmission System',
          imgName: 'transmissionSystem'
        },
        {
          title: 'Electrical System',
          imgName: 'electricalSystem'
        },
        {
          title: 'Lighting System',
          imgName: 'lightingSystem'
        }
      ],
      productTypeIndex: 0,
      startTime: 0,
      endTime: new Date().getTime()
    };
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.windowHeight = document.documentElement.clientHeight;
      })();
    };
    //注册鼠标滚动事件
    window.addEventListener("mousewheel", this.getCurrentPosition);
  },
  destroyed() {
    //注销鼠标滚动事件
    window.removeEventListener("mousewheel", this.getCurrentPosition);
  },
  methods: {
    /** 切换商品类别 */
    changeProductType(index) {
      this.productTypeIndex = index;
      //设置为第一张小图
      this.currentSmallNum = 1;
      //设置图片类别名
      this.currentProductImg = this.productTypeList[index].imgName;
    },
    /** 切换图片 */
    changeProductImg(num) {
      this.currentSmallNum = num;
    },
    /** 设置有背景图片容器的style */
    imgBoxStyle(height) {
      return {
        height: height + "px",
        "background-size": "auto " + height + "px",
      };
    },
    /** 鼠标滚轮事件 */
    getCurrentPosition(event) {
      this.startTime = new Date().getTime();
      let delta = event.detail || -event.wheelDelta;
      let range = this.endTime - this.startTime;
      if (range < -500) {
        //向下滚动，且还没滚动到底部
        if (delta > 0 && this.currentSideNav < this.sideNavList.length - 1) {
          this.currentSideNav++;
        } else if (delta < 0 && this.currentSideNav > 0) {
          //向下滚动，且未滚动到顶部
          this.currentSideNav--;
        }
        this.scrollIntoView(this.currentSideNav);
        this.endTime = new Date().getTime();
      } 
    },
    /** 滚动到指定位置 */
    scrollIntoView(index) {
      this.currentSideNav = index;
      let id = this.sideNavList[this.currentSideNav];
      let dom = document.querySelector("#" + id);
      let scrollHeight = -dom.offsetTop;
      //滚动到页脚，由于页脚占不满一屏，所以滚动高度 = 内容高度 - 窗体高度
      if (id === this.sideNavList[this.sideNavList.length - 1]) {
        //内容高度
        let pageHeight = document.querySelector(".scroll-box").offsetHeight;
        //滚动高度 = 内容高度 - 窗体高度；
        scrollHeight = -(pageHeight - this.windowHeight);
      }
      this.scrollTop = scrollHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  overflow: hidden;
  position: relative;
  .wrap {
    width: 1200px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .scroll-box {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    transition: top 0.5s linear;
  }
  .banner-box {
    width: 100%;
    height: 844px;
    background: #27272a url("../../assets/images/banner.png") no-repeat center;
    background-size: 1920px 844px;
  }
  .about-box {
    width: 100%;
    height: 942px;
    background: #2a292b url("../../assets/images/indexAboutUs.png") no-repeat center;
    background-size: 1920px 942px;
    position: relative;
    .about-center {
      width: 914px;
      height: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .about-title {
      width: 100%;
      line-height: 45px;
      font-size: 45px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      padding-bottom: 69px;
    }
    p {
      width: 914px;
      line-height: 29px;
      font-size: 20px;
      color: #fff;
      text-align: justify;
      margin: 0 auto 40px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .video-box {
    width: 100%;
    height: auto;
    background: #1A1A1A;
    position: relative;
    video {
      width: 1200px;
      display: block;
      margin: 0 auto;
    }
    .video-img {
      width: 1200px;
      height: auto;
      text-align: center;
      display: block;
      margin: 0 auto;
      background: #fff;
      img {
        width: auto;
      }
    }
    .video-item {
      width: 100%;
      height: auto;
      li {
        width: 400px;
        height: auto;
        font-size: 20px;
        text-align: center;
        color: #fff;
        padding: 21px 10px 32px;
        box-sizing: border-box;
        background: #141414;
        float: left;
        &:nth-of-type(2n) {
          background: #0E0E0E;
        }
        .video-count {
          width: 100%;
          line-height: 48px;
          font-size: 48px;
          font-weight: bold;
          color: #CB2024;
          margin-bottom: 28px;
        }
        .video-title {
          width: 100%;
          line-height: 20px;
        }
      }
    }
  }
  .product-box {
    width: 100%;
    height: auto;
    background: #1F1F1F;
    position: relative;
    .product-top {
      width: 100%;
      .product-side {
        width: 295px;
        font-size: 18px;
        color: #fff;
        background: #2B2B2B;
        float: left;
        padding: 0 55px;
        box-sizing: border-box;
        li {
          width: 100%;
          height: 16.666%;
          display: flex;
          align-items: center;
          cursor: pointer;
          &.active {
            color: #CB2024;
          }
        }
      }
      .product-big-img {
        width: 898px;
        margin-left: 302px;
        display: block;
      }
    }
    .product-item {
      width: 100%;
      height: auto;
      margin-top: 7px;
      img {
        width: 295px;
        height: 100%;
        margin-right: 6px;
        background: #2b2b2b;
        cursor: pointer;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  .standards-box {
    width: 100%;
    position: relative;
    background: #1A1A1A;
    .standards-article {
      width: 100%;
      height: 350px;
      color: #fff;
      background: url("../../assets/images/OEM.png") no-repeat left center;
      background-size: contain;
      padding-left: 479px;
      box-sizing: border-box;
      .standards-article-title {
        width: 100%;
        line-height: 100px;
        font-size: 80px;
        font-weight: bold;
        text-shadow: 3px 4px 0px #CB2024;
        padding-bottom: 50px;
      }
      .standards-article-text {
        width: 100%;
        line-height: 30px;
        font-size: 20px;
        text-align: justify;
      }
      .standards-article-big-text {
        width: 100%;
        line-height: 50px;
        font-size: 40px;
        text-align: justify;
        padding-bottom: 10px;
        span {
          font-size: 50px;
          font-weight: bold;
        }
      }
    }
    .standards-introduce {
      width: 100%;
      height: auto;
      .standards-introduce-item {
        width: 350px;
        height: auto;
        background: #3D3D3D;
        margin-right: 74px;
        padding: 24px;
        box-sizing: border-box;
        float: left;
        &:last-of-type {
          margin-right: 0;
        }
        img {
          display: block;
          margin: 0 auto;
        }
        .standards-introduce-text {
          width: 100%;
          min-height: 66px;
          line-height: 22px;
          font-size: 16px;
          color: #fff;
          text-align: center;
          margin-top: 20px;
        }
      }
    }
  }
  .side-nav {
    width: 18px;
    height: auto;
    position: fixed;
    right: 103px;
    top: 50%;
    transform: translateY(-50%);
    li {
      width: 18px;
      height: 18px;
      margin-bottom: 13px;
      display: block;
      .iconfont {
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }
      &.active {
        .iconfont {
          color: #c02d31;
        }
      }
    }
  }
}
</style>
